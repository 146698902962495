
















































































































































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import InviteLinkEditorMessage from '../HelpMessages/editor/InviteLinkEditorMessage.vue'
import { FieldsShowTo, InviteLinkApproveType, InviteLinkWithKey } from '@/includes/logic/InviteLinks/types'
import {
  ButtonBuildersGroups
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { ButtonTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/types'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import ActivationActionData
  from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { UserActivationActionType } from "@/includes/types/PmConfig.types";

@Component({
  data() {
    return {
      InviteLinkApproveType,
      EditorButtonsHelpView,
      InviteLinkEditorMessage,
      MediaEditorMode,
    }
  },
  components: {
    MultiMessageEditorWithMediaInput,
    ActivationActionData
  }
})
export default class InviteLinkActionSetting extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!: InviteLinkWithKey['config']

  @Prop({ type: Object, required: false, default: null }) fieldsShowTo!: FieldsShowTo | null

  @Prop({ type: Object, required: false, default: null }) readonly !: FieldsShowTo | null

  @Prop() channelId!: number

  @Prop({ type: Array, required: true }) approveTypeOptions!: Array<SelectOption>

  template = 0

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionType.Flow ]
    }
  }

  get isManualApprove() {
    return this.model.approve === InviteLinkApproveType.Manual
  }

  get isAutoApprove() {
    return this.model.approve === InviteLinkApproveType.Auto
  }

  isVisible(field: keyof FieldsShowTo) {
    return (this.fieldsShowTo && this.fieldsShowTo[field]) ?? true
  }

  isReadonly(field: keyof FieldsShowTo) {
    return (this.readonly && this.readonly[field]) ?? false
  }

  approveSetter(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (val !== InviteLinkApproveType.None) {
        this.model.limit = 0

        if (!this.model.message) {
          this.$set(this.model, 'message', [ {
            attachments: [],
            text: '',
            buttons: [],
            send_after: 0,
            remove_previous: false,
            pin: false,
            disable_link_preview: false,
            disable_notify: false,
          } ])
        }
      } else {
        delete this.model.message
      }

      if (val === InviteLinkApproveType.Auto) {
        this.model.user_reject_action = null

      }

      this.template += 1
      this.model.approve = val
    }

    return arg
  }

  get autoApproveButtons() {
    const message = this.model.message;

    if (message && message.every(m => !m.buttons?.length || m.buttons.every(r => !r.length))) {
      return [
        new ButtonBuildersGroups(this.$t('editor_buttons_list_group_common').toString(), this.newCaptchaButton),
        new ButtonBuildersGroups(this.$t('editor_buttons_list_group_inline').toString(),this.newPmButtons),
      ]
    }

    if (this.hasCaptchaButton) {
      return this.newCaptchaButton
    } else {
      return this.newPmButtons
    }
  }

  get hasCaptchaButton() {
    return this.model.message && this.model.message
      .some(m => m.buttons && m.buttons.length && m.buttons.some(r => r.length && r.some(b => b.type === ButtonTypes.Text)))
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }

}
